/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Provider } from "react-redux"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Meta from "./Meta"
import "./layout.scss"
import { store } from "./../app/store"

import Header001 from "./Headers/Header001"
import Footer001 from "./Footers/Footer001"

const Layout = props => {
  const [loaded, setLoaded] = React.useState()

  React.useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Provider store={store}>
      <div className={"page-colour-" + props.pageColour} id="page">
        <CssBaseline />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <link
          rel={loaded ? "stylesheet" : "preload"}
          href="https://fonts.googleapis.com/icon?family=Material+Icons&display=swap"
          as="style"
        />
        <Meta meta={props.meta} title={props.title} path={props.path} />

        <Header001 />

        <main>{props.children}</main>

        <Footer001 />
      </div>
    </Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
}

export default Layout
