import React, { Component } from "react"
import { Helmet } from "react-helmet"
import he from "he"
import settings from "../../../.brew-cache/settings.json"

class Meta extends Component {
  getSchema() {
    const schemaOrgJSONLD = []

    // Add any custom schema objects being passed through from the page
    if (this.props.meta.schema) {
      this.props.meta.schema.map(item => {
        return schemaOrgJSONLD.push(item)
      })
    }
    return JSON.stringify(schemaOrgJSONLD)
  }

  render() {
    const meta = this.props.meta
    const siteURL = process.env.GATSBY_FRONTEND_URL
    const siteName = settings.themeOptions.siteName
    const defaultImage =
      settings.themeOptions.ThemeOptions.defaultImages.schemaAndShareImage
        .sourceUrl
    const favicon =
      settings.themeOptions.ThemeOptions.defaultImages.favicon.sourceUrl

    return (
      <>
        <Helmet
          encodeSpecialCharacters={false}
          htmlAttributes={{ lang: meta.locale ? meta.locale : "en" }}
        >
          <title>
            {meta.metaTitle ? he.decode(meta.metaTitle) : this.props.title}
          </title>
          <html lang="en" />
          <meta name="description" content={meta.metaDescription} />

          {meta.canonical !== undefined ? (
            <link rel="canonical" href={this.siteURL + "/" + meta.canonical} />
          ) : null}

          <meta name="docsearch:version" content="2.0" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
          />

          {/* Schema */}
          <script type="application/ld+json">{this.getSchema()}</script>

          {/* OpenGraph */}
          {meta.ogTitle ? (
            <meta property="og:url" content={siteURL + this.props.path} />
          ) : null}
          {meta.ogTitle ? <meta property="og:type" content="website" /> : null}
          {meta.ogTitle ? <meta property="og:locale" content="en" /> : null}
          {meta.ogTitle ? (
            <meta property="og:site_name" content={siteName} />
          ) : null}
          {meta.ogTitle ? (
            <meta property="og:title" content={meta.ogTitle} />
          ) : null}
          {meta.ogDescription ? (
            <meta property="og:description" content={meta.ogDescription} />
          ) : null}
          {meta.ogImage ? (
            <meta property="og:image" content={siteURL + meta.ogImage} />
          ) : (
            <meta property="og:image" content={defaultImage} />
          )}

          {/* Twitter Card */}
          {meta.twitterTitle ? (
            <meta
              name="twitter:site"
              content={settings.themeOptions.ThemeOptions.twitterHandle}
            />
          ) : null}
          {meta.twitterTitle ? (
            <meta name="twitter:title" content={meta.twitterTitle} />
          ) : null}
          {meta.twitterTitle ? (
            <meta
              name="twitter:description"
              content={meta.twitterDescription}
            />
          ) : null}
          {meta.twitterTitle ? (
            <meta name="twitter:card" content={meta.twitterCardType} />
          ) : null}
          {meta.twitterImage ? (
            <meta name="twitter:image" content={siteURL + meta.twitterImage} />
          ) : (
            <meta name="twitter:image" content={defaultImage} />
          )}

          <link rel="apple-touch-icon" sizes="57x57" href={favicon} />
          <link rel="apple-touch-icon" sizes="60x60" href={favicon} />
          <link rel="apple-touch-icon" sizes="72x72" href={favicon} />
          <link rel="apple-touch-icon" sizes="76x76" href={favicon} />
          <link rel="apple-touch-icon" sizes="114x114" href={favicon} />
          <link rel="apple-touch-icon" sizes="120x120" href={favicon} />
          <link rel="apple-touch-icon" sizes="144x144" href={favicon} />
          <link rel="apple-touch-icon" sizes="152x152" href={favicon} />
          <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
          <link rel="icon" type="image/png" sizes="192x192" href={favicon} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
          <link rel="icon" type="image/png" sizes="96x96" href={favicon} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content={favicon} />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
      </>
    )
  }
}

export default Meta
