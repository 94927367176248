import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import Link from "../../Link"
import parse from "html-react-parser"
import styles from "./styles"

import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

const useStyles = makeStyles(theme => styles(theme))

export default function Header001(props) {
  const [open, setOpen] = React.useState(false)
  const [openSub, setOpenSub] = React.useState("")

  const handleOpen = () => {
    setOpen(!open)
  }

  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query Header001Items {
      allWpMenu(filter: { locations: { in: HEADER_MENU } }) {
        edges {
          node {
            id
            name
            slug
            menuItems {
              nodes {
                id
                title
                url
                path
                label
                target
                order
                parentId
                cssClasses
                childItems {
                  nodes {
                    id
                    title
                    url
                    path
                    label
                    target
                    order
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={classes.header001} open={open}>
      <div className="wrapper">
        <div className="site-logo">
          <Link to="/">
            <svg x="0px" y="0px" viewBox="0 0 325 68">
              <path d="M95,11.1v5.5c0,1.9,0.7,2.9,2.3,2.9c1.7,0,2.3-1.3,2.3-2.6v-5.7h1.2v5.7c0,2-1.3,3.5-3.4,3.5c-2.5,0-3.6-1.3-3.6-3.7v-5.5 H95z" />
              <path d="M111.4,20.2h-1c-2-2.4-5.3-6.4-5.9-7.2h0v7.2h-1v-9.1h0.9c2,2.4,5.3,6.3,5.9,7.2h0v-7.2h1L111.4,20.2L111.4,20.2z" />
              <path d="M115.4,11.1h-1.3v9.1h1.3V11.1z" />
              <path d="M120.4,20.2l-3.3-9l1.3-0.2c0.9,2.5,1.7,5,2.6,7.8h0c0.9-2.8,1.8-5.3,2.7-7.8l1.1,0.2l-3.6,9H120.4z" />
              <path d="M131,12.1h-3.2V15h3.1v0.9h-3.1v3.4h3.8v0.9h-5v-9.1h4.5V12.1z" />
              <path d="M134.8,15.6h0.7c1.6,0,1.9-1.2,1.9-2c0-1.1-0.7-1.5-1.5-1.5h-1.1V15.6z M133.6,11.1h2.6c1.5,0,2.6,0.8,2.6,2.3 c0,1.3-0.6,2.1-1.5,2.6l2.2,3.9l-1.3,0.4l-2.1-3.9h-1.3v3.8h-1.3L133.6,11.1L133.6,11.1z" />
              <path d="M145.6,12.5c-0.4-0.3-1-0.6-1.6-0.6c-0.9,0-1.4,0.5-1.4,1.2c0,0.6,0.6,1.1,1.7,2c1.2,0.9,2,1.7,2,2.9c0,1.4-1.4,2.4-2.8,2.4 c-0.9,0-1.9-0.3-2.5-0.9l0.6-0.8c0.5,0.4,1.1,0.8,2,0.8c0.9,0,1.6-0.5,1.6-1.5c0-1.1-1.4-1.9-2.1-2.4c-0.8-0.6-1.6-1.1-1.6-2.4c0-1.2,1.2-2.1,2.6-2.1c0.9,0,1.7,0.3,2.2,0.9L145.6,12.5z" />
              <path d="M150,11.1h-1.3v9.1h1.3V11.1z" />
              <path d="M154.7,20.2v-8.2h-2.9v-0.9h7v0.9h-2.9v8.2H154.7z" />
              <path d="M162.2,20.2v-3.9l-2.7-5l1.4-0.3l2.2,4.3l2.4-4.3l1.1,0.2l-3,5.1v3.9H162.2z" />
              <path d="M179.7,15.5c0-1.7-0.9-3.6-3.1-3.6c-2.2,0-3.1,1.9-3.1,3.6c0,1.8,0.8,3.9,3.1,3.9C178.9,19.5,179.7,17.3,179.7,15.5zM172.1,15.5c0-2.5,1.9-4.5,4.5-4.5c2.6,0,4.5,2,4.5,4.5c0,2.7-1.7,4.8-4.5,4.8C173.8,20.4,172.1,18.2,172.1,15.5z" />
              <path d="M187.9,12.1h-3.2v3.4h3.1v0.9h-3.1v3.8h-1.3v-9.1h4.4V12.1z" />
              <path d="M35.2,18.9C33,21.2,27.5,27.8,24.6,30c-1.2,0.9-2.4,1-4.4,0.7c-2.8-0.4-5.5-7.5-7-12.1c-1.5-4.5-3.1-17.1-3.1-17.1L0,9.6c0,0,0,0.6,0.2,1.7c1.3,7.2,2.4,11.1,5,16c3.3,6.4,6.3,10.6,11.1,10.7c5.8,0.2,8.9-3.8,12.1-8c2.8-3.6,6.4-9.8,9.1-13.2v-0.3C36.7,17.4,36,18.2,35.2,18.9z" />
              <path d="M58.7,37.4c-4.9-0.6-6.5-2.8-9.6-6.7c0,0-2.4-4-4.1-7.7c-2.5-5.4-2.8-8.9-5.9-7.6c-0.7,0.3-1,0.8-1.7,1.5V68l23.9-19l24.4,19V4c-4.2,5.9-12,21.9-13.8,24.4C69.4,31.8,65.4,38.2,58.7,37.4z" />
              <path d="M37.4,0v16.6c2.5-2.7,4.9-5.2,6.2-6c1.7-1,6.1-2.8,7.9,2c2.1,5.5,5.3,11.9,8.1,15c3.5,3.9,5.9,3.3,8.8,0.2c2.6-2.8,13.7-21.9,17.2-26V0H37.4z" />
              <path d="M106.7,26.3c1.5,5,3,9.9,4.4,15.5h0.1c1.5-5.7,3-10.7,4.5-15.8l2,0.4l-5.7,18.3h-2.2c-1.5-4.9-2.9-9.8-4.3-15.2h-0.1c-1.4,5.4-2.9,10.3-4.4,15.2h-2.2l-5.4-18.1l2.4-0.5c1.4,5,2.9,10.1,4.3,15.8h0.1c1.5-5.6,3-10.5,4.5-15.5H106.7z" />
              <path d="M134.9,35.2c0-3.4-1.8-7.3-6.3-7.3c-4.5,0-6.3,3.9-6.3,7.3c0,3.6,1.6,8,6.3,8C133.3,43.1,134.9,38.7,134.9,35.2zM119.4,35.2c0-5.1,3.9-9.2,9.1-9.2c5.3,0,9.1,4,9.1,9.2c0,5.5-3.5,9.8-9.1,9.8C122.9,45,119.4,40.6,119.4,35.2z" />
              <path d="M141.7,26.3h2.5v16.5h7.6v1.8h-10.1V26.3z" />
              <path d="M158.1,44.6l-6.8-18.1l2.6-0.5c1.8,5,3.5,10,5.3,15.7h0.1c1.8-5.6,3.7-10.7,5.6-15.7l2.3,0.3l-7.3,18.3H158.1z" />
              <path d="M178.7,28.1h-6.5v5.9h6.3v1.8h-6.3v6.9h7.6v1.8h-10.1V26.3h9V28.1z" />
              <path d="M185.7,35.2h1.4c3.2,0,3.8-2.4,3.8-4c0-2.3-1.4-3.1-3.1-3.1h-2.2V35.2z M183.1,26.3h5.2c3,0,5.3,1.5,5.3,4.7c0,2.7-1.3,4.2-3.1,5.4l4.5,7.8l-2.6,0.8l-4.2-7.9h-2.7v7.6h-2.5V26.3z" />
              <path d="M212.1,44.6h-2.5v-9.1h-8.9v9.1h-2.5V26.3h2.5v7.4h8.9v-7.4h2.5V44.6z" />
              <path d="M225.5,37.4c-0.9-2.5-1.8-5.1-2.7-8.4h-0.1c-0.9,3.3-1.9,5.8-2.9,8.4H225.5z M224.1,26.3l6.8,18.3H228l-1.9-5.4h-7l-2,5.4h-2.5l7.4-18.3H224.1z" />
              <path d="M253.3,44.6h-2.5V29.7h-0.1c-0.7,2.1-4.9,10.3-7.3,14.9h-1c-2.3-4.7-6-12.5-6.8-14.9h-0.1v14.9h-2.1V26.3h2.7c2.3,4.9,4.7,9.8,7,14.9c2.6-5.2,5.2-10.1,7.7-14.9h2.4V44.6z" />
              <path d="M260.7,44.6h-2.5V26.3h4.6c3.9,0,6.2,1.7,6.2,5.3c0,3.1-2.6,5.9-6.7,5.9h-0.8v-1.7l0.8,0c2.5-0.1,4-1.4,4-4.2c0-2-1.1-3.5-3.8-3.5h-1.7L260.7,44.6L260.7,44.6z" />
              <path d="M276.5,44.6V28.1h-5.8v-1.8h14.2v1.8H279v16.5H276.5z" />
              <path d="M302.3,35.2c0-3.4-1.8-7.3-6.3-7.3c-4.5,0-6.3,3.9-6.3,7.3c0,3.6,1.6,8,6.3,8C300.6,43.1,302.3,38.7,302.3,35.2zM286.8,35.2c0-5.1,3.9-9.2,9.1-9.2c5.3,0,9.1,4,9.1,9.2c0,5.5-3.5,9.8-9.1,9.8C290.3,45,286.8,40.6,286.8,35.2z" />
              <path d="M325,44.6h-2c-4-4.8-10.8-13-11.9-14.6h-0.1v14.6H309V26.3h1.9c4,4.8,10.7,12.8,12,14.6h0.1V26.3h2.1L325,44.6L325,44.6z" />
            </svg>
          </Link>
        </div>

        <button
          className={`menu-trigger `}
          onClick={handleOpen}
          onKeyDown={handleOpen}
          aria-label="Open Menu"
        >
          {open === true && <CloseIcon />}
          {open === false && <MenuIcon />}
        </button>

        <div className="site-nav">
          {data.allWpMenu.edges[0].node.menuItems.nodes.map(section => {
            // console.log(section);
            if (section.childItems.nodes.length > 0) {
              return (
                <div
                  className="sub-menu"
                  key={section.id}
                  data-active={openSub === section.id ? true : false}
                >
                  <button
                    onClick={() => {
                      if (openSub === section.id) {
                        setOpenSub("")
                      } else {
                        setOpenSub(section.id)
                      }
                    }}
                    onKeyDown={() => {
                      if (openSub === section.id) {
                        setOpenSub("")
                      } else {
                        setOpenSub(section.id)
                      }
                    }}
                  >
                    {parse(section.label !== null ? section.label : "")}{" "}
                    <ArrowDropDownIcon />
                  </button>
                  <div className="sub-items-wrap">
                    <div className="sub-items">
                      {section.childItems.nodes.map(child => {
                        return (
                          <Link key={child.id} to={child.path}>
                            {parse(child.label !== null ? child.label : "")}
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            } else if (section.parentId == null) {
              return (
                <Link
                  key={section.id}
                  to={section.path}
                  className={section.cssClasses}
                >
                  {parse(section.label !== null ? section.label : "")}
                </Link>
              )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}
