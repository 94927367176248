export default theme => ({
  // eslint-disable-line
  footer001: {
    borderTop: "2px solid var(--uow-orange-yellow)",
    paddingTop: 50,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: "var(--uow-royal-blue)",
    [theme.breakpoints.up("md")]: {
      paddingTop: 100,
    },
    "& .site-logo": {
      maxWidth: "calc(100% - 24px)",
      flex: "0 0 calc(100% - 24px)",
      margin: "0 auto",
      [theme.breakpoints.up("md")]: {
        flex: "1",
        paddingRight: 12,
      },
      "& svg": {
        maxWidth: 354,
        margin: "0 auto 12px",
        display: "block",
        [theme.breakpoints.up("md")]: {
          margin: "0 0 12px",
        },
        "& *": {
          fill: "var(--uow-white)",
        },
      },
      "& p": {
        color: "var(--uow-white)",
        textAlign: "center",
        marginBottom: 24,
        [theme.breakpoints.up("md")]: {
          textAlign: "left",
        },
      },
    },
    "& .footer-columns": {
      flex: "0 0 calc(100% + 24px)",
      maxWidth: "calc(100% + 24px)",
      marginLeft: -12,
      marginRight: -12,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 448px",
        maxWidth: "448px",
        marginLeft: 0,
        marginRight: 0,
      },
      "& .col": {
        flex: "0 0 50%",
        maxWidth: "50%",
        padding: "0 12px",
        "& .footer-h": {
          borderBottom: "1px solid var(--uow-gray-blue)",
          fontSize: 18,
          letterSpacing: "0.032em",
          lineHeight: "32px",
          color: "var(--uow-gray-blue)",
          fontWeight: "bold",
          textTransform: "uppercase",
          marginBottom: 32,
        },
        "& a": {
          color: "var(--uow-white)",
          fontWeight: 900,
          letterSpacing: "0.016em",
          lineHeight: "32px",
          fontSize: 18,
          display: "block",
          textDecoration: "none",
          marginBottom: 24,
          transition: "color .3s ease-in-out",
          "&:hover, &:focus": {
            color: "var(--uow-orange-yellow)",
          },
        },
        "&.social": {
          "& a": {
            "& svg": {
              color: "var(--uow-orange-yellow)",
              width: 18,
              height: 18,
              display: "inline-block",
              marginRight: 12,
              verticalAlign: "text-top",
            },
            "& span": {
              display: "inline-block",
              verticalAlign: "baseline",
            },
          },
        },
      },
    },
    "& .strapline": {
      flex: "0 0 calc(100% - 24px)",
      maxWidth: "calc(100% - 24px)",
      fontFamily: "var(--uow-header-font)",
      fontSize: 42,
      lineHeight: "56px",
      fontWeight: "bold",
      textAlign: "center",
      letterSpacing: "0",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 224px",
        maxWidth: "224px",
        textAlign: "right",
      },
      "& span": {
        display: "block",
      },
    },
    "& .footer-bottom": {
      borderTop: "1px solid var(--uow-light-gray)",
      padding: "40px",
      flex: "0 0 100%",
      minWidth: "100%",
      marginTop: 75,
      "& p": {
        textAlign: "center",
        color: "var(--uow-white)",
        "&.credit": {
          "& a": {
            color: "#6AA43B",
            fontWeight: "bold",
            textDecoration: "none",
            transition: "opacity .3s ease-in-out",
            "&:hover, &:focus": {
              opacity: 0.8,
            },
          },
        },
      },
    },
  },
})
