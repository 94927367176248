import arrow from "../../../images/arrow.png"

export default theme => ({
  // eslint-disable-line
  header001: {
    borderTop: "3px solid var(--uow-royal-blue)",
    padding: "20px 0 10px",
    paddingLeft: 12,
    paddingRight: 12,
    position: "relative",
    zIndex: 1,
    "& .wrapper": {
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .site-logo": {
      maxWidth: 180,
      flex: "0 0 180px",
      [theme.breakpoints.up("lg")]: {
        maxWidth: 252,
        flex: "0 0 252px",
      },
      "& svg": {
        "& *": {
          fill: "var(--uow-royal-blue)",
        },
      },
    },
    "& .menu-trigger": {
      padding: 0,
      backgroundColor: "transparent",
      border: "none",
      fontSize: 0,
      color: "var(--uow-royal-blue)",
      cursor: "pointer",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    "& .site-nav": {
      flex: "0 0 100%",
      minWidth: "100%",
      overflow: "hidden",
      height: 0,
      opacity: 0,
      transition: "opacity .3s ease-in-out",
      [theme.breakpoints.up("md")]: {
        opacity: 1,
        flex: "1",
        minWidth: 0,
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        overflow: "visible",
        "& > a": {
          marginRight: 32,
        },
      },
      "& > a": {
        display: "table",
        fontSize: 16,
        color: "var(--uow-royal-blue)",
        letterSpacing: "0.2em",
        lineHeight: "32px",
        textTransform: "uppercase",
        textDecoration: "none",
        position: "relative",
        marginBottom: 6,
        [theme.breakpoints.up("lg")]: {
          marginRight: 64,
        },
        "&:before": {
          content: '""',
          display: "block",
          backgroundColor: "var(--uow-royal-blue)",
          height: 1,
          position: "absolute",
          bottom: 0,
          left: "50%",
          right: "50%",
          transition: "all .3s ease-in-out",
        },
        "&:hover, &:focus": {
          "&:before": {
            left: 15,
            right: 15,
          },
        },
        "&.button": {
          fontSize: 18,
          padding: "14px 32px 10px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.25)",
          borderRadius: 4,
          fontWeight: "bold",
          letterSpacing: "0.088em",
          marginRight: 28,
          transition: "margin-right .3s ease-in-out",
          "&:before": {
            position: "relative",
            backgroundImage: "url(" + arrow + ")",
            backgroundSize: "14px 16px",
            width: 0,
            height: 16,
            marginRight: 0,
            display: "inline-block",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center right",
            backgroundColor: "transparent",
            left: "auto",
            right: "auto",
            verticalAlign: "middle",
            marginTop: -4,
          },
          "&:hover, &:focus": {
            marginRight: 0,
            "&:before": {
              left: "auto",
              right: "auto",
              width: 14,
              marginRight: 14,
            },
          },
        },
      },
      "& button": {},
      "& div.sub-menu": {
        "& .sub-items-wrap": {
          "& .sub-items": {
            "& > a": {
              "&:hover, &:focus": {},
            },
          },
        },
        '&[data-active="true"]': {
          "& .sub-items-wrap": {},
        },
      },
    },
    "&[open]": {
      "& .site-nav": {
        height: "auto",
        opacity: 1,
        padding: "12px 0",
        overflow: "visible",
      },
    },
  },
})
